<script lang="ts">
import { beaconService } from '../../services/APIs/beacon.service'
import { loggedInUser } from '../../store/stores'
import { currAccount } from '../../store/stores'
import { _ } from 'svelte-i18n'

function openBeacon() {
	if (window.Beacon) window.Beacon('close')
	if ($currAccount && $loggedInUser) {
		beaconService.navigateBeacon('/ask/')
		beaconService.identifyBeacon()
		beaconService.prefillBeacon(
			`${$currAccount.name} Discount`,
			`Hi, I'm inquiring about DogBase discount or per user billing\n--- \n Name: ${
				$loggedInUser.firstName + ' ' + $loggedInUser.lastName
			} \n Account: ${$currAccount.name}\n Dogs: ${$currAccount.dogIds.length}\n Users: ${
				$currAccount.userIds.length
			}\n Teams: ${$currAccount.teamIds.length}`
		)
		beaconService.openBeacon()
	}
}
</script>

<div class="alert-container flex align-center justify-center">
	<h2 class="alert-text">
		{$_('per_user_billing')}
		<button
			on:click={() => openBeacon()}
			class="alert-link">
			{$_('send_us')}
		</button>
	</h2>
</div>

<style lang="scss">
.alert-container {
	background: #264654;
	padding: 1.5rem 1rem;
	border-radius: 5px;
	margin: 0 0 1.5rem 0;

	.alert-text {
		font-size: 1.125rem;
		color: white;
	}
	.alert-link {
		color: #ffa470;
	}
	.alert-link:hover {
		color: #ffa470;
	}
}
</style>
