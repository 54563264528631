<script lang="ts">
import Icon from 'svelte-icons-pack'
import CgSupport from 'svelte-icons-pack/cg/CgSupport'
import AiOutlineLineChart from 'svelte-icons-pack/ai/AiOutlineLineChart'
import TiCloudStorageOutline from 'svelte-icons-pack/ti/TiCloudStorageOutline'
import IoSchool from 'svelte-icons-pack/io/IoSchool'
import { loggedInUser } from '../../store/stores'
import { currAccount } from '../../store/stores'
import { _ } from 'svelte-i18n'
import { beaconService } from '../../services/APIs/beacon.service'

function openBeacon() {
	if (window.Beacon) window.Beacon('close')
	if ($currAccount && $loggedInUser) {
		beaconService.navigateBeacon('/ask/')
		beaconService.identifyBeacon()
		beaconService.prefillBeacon(
			`${$currAccount.name} Enterprise Solutions`,
			`Hi, I'm inquiring about DogBase enterprise offerings\n--- \n Name: ${
				$loggedInUser.firstName + ' ' + $loggedInUser.lastName
			} \n Account: ${$currAccount.name}\n Dogs: ${$currAccount.dogIds.length}\n Users: ${
				$currAccount.userIds.length
			}\n Teams: ${$currAccount.teamIds.length}`
		)
		beaconService.openBeacon()
	}
}
</script>

<section class="enterprise grid">
	<div class="enterprise-solutions flex-column">
		<h1 class="title">{$_('dogbase_enterprise')}</h1>
		<h2 class="description">
			{$_('empower_your_team')}
		</h2>
		<button
			class="btn theme-btn"
			on:click={() => {
				openBeacon()
			}}>{$_('contact_us')}</button>
	</div>
	<div class="benefits grid">
		<div class="benefits-item flex">
			<div class="icon-container">
				<Icon
					src={IoSchool}
					color="#264654" />
			</div>
			<div class="flex-column">
				<h1 class="title">{$_('tailored_training_types')}</h1>
				<h2 class="description">
					{$_('create_custom_input')}
				</h2>
			</div>
		</div>
		<div class="benefits-item flex">
			<div class="icon-container">
				<Icon
					src={TiCloudStorageOutline}
					color="#264654" />
			</div>
			<div class="flex-column">
				<h1 class="title">{$_('unlimited_storage')}</h1>
				<h2 class="description">
					{$_('store_training_data')}
				</h2>
			</div>
		</div>
		<div class="benefits-item flex">
			<div class="icon-container">
				<Icon
					src={CgSupport}
					color="#264654" />
			</div>
			<div class="flex-column">
				<h1 class="title">{$_('account_support')}</h1>
				<h2 class="description">
					{$_('get_account_manager')}
				</h2>
			</div>
		</div>
		<div class="benefits-item flex">
			<div class="icon-container">
				<Icon
					src={AiOutlineLineChart}
					color="#264654" />
			</div>
			<div class="flex-column">
				<h1 class="title">{$_('advanced_insights')}</h1>
				<h2 class="description">
					{$_('gain_insights')}
				</h2>
			</div>
		</div>
	</div>
</section>
<section class="footer grid">
	<div class="footer-item payment-methods flex-column">
		<h2 class="title">{$_('payment_methods')}</h2>
		<li class="payment-methods-list flex align-center">
			<ul class="payment-method">
				<img
					src="../../../assets/img/payment/visa-logo.svg"
					alt="payment-method" />
			</ul>
			<ul class="payment-method">
				<img
					src="../../../assets/img/payment/mastercard-logo.svg"
					alt="payment-method" />
			</ul>
			<ul class="payment-method amex">
				<img
					src="../../../assets/img/payment/amex-logo.svg"
					alt="payment-method" />
			</ul>
			<ul class="payment-method">
				<img
					src="../../../assets/img/payment/unionpay-logo.svg"
					alt="payment-method" />
			</ul>
		</li>
	</div>
	<div class="footer-item secure-payment flex-row">
		<div class="img-container flex align-center">
			<img
				src="../../../assets/img/payment/secure-payment.svg"
				alt="secure-payment" />
		</div>
		<div class="flex-column">
			<h2 class="title">{$_('ssl_payment')}</h2>
			<h2 class="description">{$_('your_info_is_protected')}</h2>
		</div>
	</div>
	<div class="footer-item money-back flex-row">
		<div class="img-container flex align-center">
			<img
				src="../../../assets/img/payment/moneyback.svg"
				alt="money-back" />
		</div>
		<div class="flex-column">
			<h2 class="title">{$_('money_back')}</h2>
			<h2 class="description">
				Try DogBase for 30 days and if you're not 100% satisfied, get your money back.
			</h2>
		</div>
	</div>
	<div class="footer-item paddle flex-row">
		<div class="flex-column">
			<div class="img-container flex align-center">
				<img
					src="../../../assets/img/payment/paddle-logo.png"
					alt="paddle-logo" />
			</div>
			<h2 class="description">{$_('payments_by_paddle')}</h2>
		</div>
	</div>
</section>

<style lang="scss">
.footer {
	grid-template-columns: 240px repeat(2, 300px) 240px;
	border-top: 1px solid rgba(0, 0, 0, 0.06);
	justify-content: space-around;
	align-items: center;
	padding: 40px 18px 26px 18px;
	width: 100%;
	margin-top: 0.5rem;

	@media screen and (max-width: 900px) {
		grid-template-columns: auto;
		grid-template-rows: 1fr;
		row-gap: 2rem;
	}

	.footer-item {
		gap: 8px;

		&:last-child {
			border-right: none;
		}

		.payment-methods-list {
			padding-bottom: 15px;
			gap: 5px;

			ul {
				border: 1px solid rgba(0, 0, 0, 0.1);
				border-radius: 6px;
				padding: 1.5px;
				height: 32px;
				width: 48px;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}

				&.amex {
					background-color: #016fd0;
				}
			}
		}

		&.secure-payment,
		&.payment-methods,
		&.money-back,
		&.paddle {
			.title {
				font-size: 13px;
			}

			.description {
				font-family: Nunito;
				font-size: 13px;
				height: 47px;
			}

			.img-container {
				width: 55px;
				height: 55px;
				flex-shrink: 0;
				img {
					width: 50px;
				}
			}
		}

		&.secure-payment,
		&.money-back {
			gap: 6px;

			.flex-column {
				gap: 6px;
			}
		}

		&.secure-payment {
			gap: 10px;
			.img-container {
				margin-top: 5px;
				width: 45px;
				height: 45px;
			}
		}
	}
}

.enterprise {
	grid-template-columns: 2fr 3fr;
	padding: 30px 30px 0 30px;
	background-color: rgba(0, 0, 0, 0.03);
	border-radius: 6px;
	width: 100%;
	margin-bottom: 0.5rem;

	@media screen and (max-width: 900px) {
		grid-template-columns: auto;
		grid-template-rows: 1fr;
	}

	.enterprise-solutions {
		padding: 60px 8px;
		gap: 8px;

		.title {
			font-family: Nunito-Bold;
			font-size: 20px;
			color: var(--clr-secondary);
		}

		.description {
			font-family: Nunito;
			margin-bottom: 6px;
			font-size: 15px;
		}

		.btn {
			align-self: flex-start;
		}
	}

	.benefits {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 0px;
		grid-row-gap: 0px;

		@media screen and (max-width: 900px) {
			grid-template-columns: auto;
			grid-template-rows: 1fr;
		}

		.benefits-item {
			padding: 18px;
			gap: 8px;

			.title {
				font-size: 15px;
			}
			.description {
				font-family: Nunito;
				margin-bottom: 6px;
				font-size: 13px;
			}

			.flex-column {
				gap: 3px;
			}

			.icon-container {
				color: var(--clr-primary);
				font-size: 32px;
				flex-shrink: 0;
			}
		}
	}
}
</style>
