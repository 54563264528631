<script lang="ts">
import { _ } from 'svelte-i18n'
import NavigationAlert from '../header/NavigationAlert.svelte'
import { clearAllStores } from '../../store/trainingWidgetsStore'

export let productInfo: any
export let productId: string
export let billingCycle: string

let showAlert = false
let alertMessage: string
let selectedPlan: any

function handleNavigation(item: any) {
	showAlert = true
	alertMessage = $_('non_profit_plan_alert')
	selectedPlan = item
}

function onAlertConfirm() {
	clearAllStores()
	productId = selectedPlan.price.id
	showAlert = false
}

function onModalCancel() {
	showAlert = false
}
</script>

<section class="pricing-section bg-12">
	<div class="pricing pricing--palden flex-column">
		<!-- <div class="billing-cycle flex">
			<div class="billing-cycle-inner">
				<p class="select-billing-cycle">
					<SelectButton
						bind:value={billingCycle}
						choices={[
							{ label: $_('monthly'), value: 'month' },
							{ label: $_('yearly'), value: 'year' }
						]} />
				</p>
			</div>
		</div> -->
		<div class="product-container flex">
			{#each productInfo as item, index}
				<div class={`plan-1 pricing__item`}>
					{#if index == 1}
						<span class="tag">Pay Annually</span>
					{:else}
						<span class="tag">Pay Monthly</span>
					{/if}

					<!-- {#if billingCycle == 'year'}
						<div class="ribbon ribbon-top-right"><span>15% OFF!</span></div>
					{/if} -->
					<div class={`pricing__deco-1 pricing__deco`}>
						<svg
							class="pricing__deco-img"
							version="1.1"
							id="Layer_1"
							preserveAspectRatio="none"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							x="0px"
							y="0px"
							width="300px"
							height="100px"
							viewBox="0 0 300 100"
							enable-background="new 0 0 300 100"
							xml:space="preserve">
							<path
								class="deco-layer deco-layer--1"
								opacity="0.6"
								fill="#FFFFFF"
								d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" />
							<path
								class="deco-layer deco-layer--2"
								opacity="0.6"
								fill="#FFFFFF"
								d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" />
							<path
								class="deco-layer deco-layer--3"
								opacity="0.7"
								fill="#FFFFFF"
								d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
H42.401L43.415,98.342z" />
							<path
								class="deco-layer deco-layer--4"
								fill="#FFFFFF"
								d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z" />
						</svg>
						<div class="pricing__price flex-column">
							<p>{item.formattedTotals.subtotal}<span class="per-dog">/per dog</span></p>
						</div>
						<h3 class="pricing__title">{item.product.name}</h3>
					</div>
					<ul class="pricing__feature-list">
						{#each Object.entries(item.product.customData) as [key, value]}
							{#if key != 'full_price'}
								{#if key == 'File Formats' || key == 'Uploads'}
									<li class="pricing__feature">{key}: {value}</li>
								{:else}
									<li class="pricing__feature">{value} {key}</li>
								{/if}
							{/if}
						{/each}
					</ul>
					<button
						class={`pricing__action-${index} pricing__action`}
						on:click={() => {
							productId = item.price.id
						}}>{$_('choose_plan')}</button>
				</div>
			{/each}
		</div>
	</div>
</section>

{#if showAlert}
	<NavigationAlert
		message={alertMessage}
		onConfirm={onAlertConfirm}
		onCancel={onModalCancel} />
{/if}

<style lang="scss">
.plan-1 {
	border: 2px solid #ffa470;

	.tag {
		width: 100%;
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
		background-color: #ffa470;
		font-weight: bold;
		color: #fff;
		border-radius: 8px;
	}
}

.billing-cycle {
	width: 100%;
	margin-top: 1rem;

	.billing-cycle-inner {
		display: flex;
		width: 100%;
		justify-content: space-between;
		gap: 3rem;
		margin: 1rem auto 1rem;
	}
}

.product-container {
	gap: 3rem;
	display: -webkit-flex;
	-webkit-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: end;
	align-items: flex-end;
	width: 100%;
	margin: 1rem auto 1rem auto;
}

.ribbon {
	z-index: 1000;
	width: 150px;
	height: 150px;
	overflow: hidden;
	position: absolute;
}
.ribbon::before,
.ribbon::after {
	position: absolute;
	z-index: -1;
	content: '';
	display: block;
	border: 5px solid #2980b9;
}

.ribbon span {
	position: absolute;
	display: block;
	width: 225px;
	padding: 15px 0;
	background-color: #3498db;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	color: #fff;
	font: 700 18px/1 'Lato', sans-serif;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	text-transform: uppercase;
	text-align: center;
}

/* top right*/
.ribbon-top-right {
	top: -10px;
	right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
	border-top-color: transparent;
	border-right-color: transparent;
}
.ribbon-top-right::before {
	top: 0;
	left: 0;
}
.ribbon-top-right::after {
	bottom: 0;
	right: 0;
}
.ribbon-top-right span {
	left: -25px;
	top: 30px;
	transform: rotate(45deg);
}
.pricing {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-justify-content: center;
	justify-content: center;
	align-items: center;
	width: fit-content;
	margin: 1rem auto 0rem;
}

.pricing-section {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	align-items: center;
}

.pricing-section {
	position: relative;
}

.pricing--palden .pricing__item {
	font-family: 'Nunito', sans-serif;
	cursor: default;
	color: #84697c;
	background: #fff;
	box-shadow: 0 0px 7px rgba(0, 0, 0, 0.1);
	border-radius: 15px;
	margin: 0.5em;
	transition: box-shadow 0.3s;
}

.pricing--palden .pricing__item:hover {
	box-shadow: 0 0px 10px rgba(0, 0, 0, 0.11);
}

.pricing--palden .pricing__deco {
	border-radius: 8px 8px 0 0;
	padding: 3em 0 6em;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	position: relative;
}

.pricing--palden .pricing__deco-1 {
	border-radius: 0;
}

.pricing--palden .pricing__deco-img {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

.pricing--palden .pricing__item--featured .pricing__deco {
	padding: 5em 0 8.885em 0;
}

.pricing--palden .pricing__title {
	font-size: 0.9rem;
	margin: 0;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: #ffffff;
}

.pricing--palden .deco-layer {
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
}

.pricing--palden .pricing__item:hover .deco-layer--1 {
	-webkit-transform: translate3d(15px, 0, 0);
	transform: translate3d(15px, 0, 0);
}

.pricing--palden .pricing__item:hover .deco-layer--2 {
	-webkit-transform: translate3d(-15px, 0, 0);
	transform: translate3d(-15px, 0, 0);
}

.per-dog {
	font-size: 0.8rem;
	color: rgb(160, 156, 156);
}

.pricing--palden .pricing__price {
	align-items: center;
	gap: 0.75rem;
	justify-content: center;
	font-size: 3rem;
	font-weight: bold;
	padding: 0;
	color: #fff;
	margin: 0 0 0.25em 0;
	line-height: 0.8;

	.full-price {
		margin-right: 0.5rem;
		font-size: 1.5rem;
		color: rgb(185, 187, 187);
	}
}

.pricing--palden .pricing__feature-list {
	margin: 0;
	padding: 0.25em 0 1.5em;
	list-style: none;
	text-align: center;
}

.pricing--palden .pricing__action {
	font-weight: bold;
	margin: auto 3em 2em 3em;
	padding: 0.8em 1.5em;
	color: #fff;
	border-radius: 30px;
	background: #2d708d;
	-webkit-transition: background-color 0.3s, box-shadow 0.3s;
	transition: background-color 0.3s, box-shadow 0.3s;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pricing--palden .pricing__action-1 {
	font-weight: bold;
	margin: auto 3em 2em 3em;
	padding: 0.8em 1.5em;
	color: #fff;
	border-radius: 30px;
	background: #ffa470;
	-webkit-transition: background-color 0.3s, box-shadow 0.3s;
	transition: background-color 0.3s, box-shadow 0.3s;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pricing--palden .pricing__action:hover,
.pricing--palden .pricing__action:focus {
	box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
	background-color: #275f77;
}

.pricing--palden .pricing__action-1:hover,
.pricing--palden .pricing__action-1:focus {
	box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
	background-color: #f38747;
}

.pricing__item {
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: stretch;
	align-items: stretch;
	text-align: center;
	-webkit-flex: 0 1 330px;
	flex: 0 1 330px;
	width: 320px;
}

.pricing__feature-list {
	text-align: left;
	margin: 0;
	list-style: none;
	text-align: center;
}

.pricing--palden .pricing__feature {
	padding: 0.4em 0;
}

@media screen and (max-width: 1530px) {
	.billing-cycle {
		width: 100%;
		margin-top: 1rem;

		.billing-cycle-inner {
			display: flex;
			width: 100%;
			justify-content: space-between;
			gap: 3rem;
			margin: 1rem auto 1rem;
		}
	}
}

@media screen and (min-width: 66.25em) {
	.pricing--palden .pricing__item {
		margin: 0 -0.25em 0.5em -0.25em;
	}
}

@media screen and (max-width: 900px) {
	.pricing {
		flex-direction: column;
		gap: 1rem;
	}

	.billing-cycle {
		width: 70%;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 0;
	}

	.billing-cycle-inner {
		align-items: center;
		gap: 0;
	}

	.billing-cycle-inner {
		margin-bottom: 0;
	}

	.product-container {
		flex-direction: column;
		gap: 1rem;
	}
}
</style>
