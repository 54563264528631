<script lang="ts">
import { onMount } from 'svelte'
import Product from '../cmps/checkout/Product.svelte'
import Payment from '../cmps/checkout/Payment.svelte'
import PaymentFooter from '../cmps/checkout/PaymentFooter.svelte'
import { _ } from 'svelte-i18n'
import PaymentAlert from '../cmps/checkout/PaymentAlert.svelte'
import * as Sentry from '@sentry/browser'
import { userMsg } from '../utils/userMsg.service'

interface LineItem {
	priceId: string
	quantity: number
}

interface Prices {
	data: {
		details: {
			lineItems: LineItem[]
		}
	}
}

let prices: Prices
let productId: ''

var billingCycle = 'month'

onMount(() => {
	getPrices(billingCycle)
})

/**
 * Retrieves the prices for a given billing cycle.
 *
 * @param {string} cycle - The billing cycle, either 'month' or 'year'.
 * @return {Promise<void>} A promise that resolves when the prices are retrieved.
 */
function getPrices(cycle: string) {
	window.Paddle.PricePreview({
		items: items
	})
		.then((result: Prices) => {
			prices = result
		})
		.catch((error: string) => {
			userMsg.errorMsg('Error: issue price preview.')
			console.warn(error)
			Sentry.captureException(error)
		})
}

var items: { priceId: string; quantity: number }[] = [
	{
		priceId: 'pri_01jp16dtmkddz23ajqy9xqwg8q',
		quantity: 1
	},
	{
		priceId: 'pri_01jp16g7ekm4jezhascv4zycd2',
		quantity: 1
	}
]
</script>

{#if prices && !productId}
	<div class="products">
		<header class="flex-column justify-center align-center form-header">
			<h1 class="title">One powerful plan, everything you need</h1>
			<h2 class="description">Get started with our all-in-one plan for you and your team.</h2>
		</header>
		<div class="plans flex-column">
			<Product
				bind:billingCycle
				bind:productId
				productInfo={prices.data.details.lineItems} />
		</div>
		<PaymentAlert />
		<footer class="products-footer flex-column align-center">
			<PaymentFooter />
		</footer>
	</div>
{/if}

{#if productId}
	<div class="btn-container">
		<button
			class="back-to-plan"
			on:click={() => {
				productId = ''
			}}>{$_('back_to_plan_selection')}</button>
	</div>
	<Payment selectedPlan={productId} />
{/if}

<style lang="scss">
@use '../styles/setup/mixins';

.products {
	@include mixins.card;
	padding: 32px 24px;
	margin: 20px 0;
	width: 100%;
	gap: 70px;
}

.form-header {
	text-align: center;

	.title {
		display: flex;
		align-items: center;
		font-size: 33px;
		font-family: Nunito-Bold;
		margin-bottom: 10px;
		gap: 16px;
	}

	.description {
		color: rgb(105, 105, 105);
		font-family: Nunito;
		font-size: 15px;
		font-weight: normal;
	}
}

.btn-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: right;

	.back-to-plan {
		font-weight: bold;
		margin-bottom: 1rem;
		padding: 1em 2em;
		color: #fff;
		border-radius: 8px;
		background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
		-webkit-transition: background-color 0.3s;
		transition: background-color 0.3s;
	}
}

@media screen and (max-width: 900px) {
	.form-header {
		text-align: center;
		.title {
			align-self: flex-start !important;
			font-size: 18px;
			margin-bottom: 24px;
		}
	}
}
</style>
